<template>
  <div class="user">
    <Navigation></Navigation>
    <transition name="slide" mode="out-in">
      <router-view class="routes"></router-view>
    </transition>
    <Footer></Footer>
  </div>
</template>

<script>
export default {
  mounted() {
    this.observeAnimation();
  },
  methods: {
    observeAnimation() {
      const observeItems = document.getElementsByClassName("observe-item");
      let observe = new IntersectionObserver((enteries) => {
        enteries.forEach((entery) => {
          if (entery.isIntersecting) {
            //if object is exsist in view port and threshold is 0
            entery.target.classList.add("show");
          }
        });
      });
      for (let i = 0; i < observeItems.length; i++) {
        observe.observe(observeItems[i]);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/style/_mixins.scss";
.user {
  min-height: 100vh;
  overflow-x: hidden;
  position: relative;
  .routes {
    padding-top: 90px;
    min-height: 75vh;

    @include md {
      padding: 90px 5px 20px 5px;
    }
    .index-table {
      border: none !important;
      background: none !important;
    }
  }
  //router-ainmation
  .slide-enter-active,
  .slide-leave-active {
    transition: all 0.5s ease;
  }
  .slide-leave {
    transform: translateX(0);
  }
  .slide-leave-to {
    transform: translateX(100%);
  }

  .slide-enter {
    transform: translateX(100%);
  }
  .slide-enter-to {
    transform: translateX(0);
  }
  //router-ainmation
}
</style>
